import { ViewModel } from "mvvm/ViewModel";
import { PageViewModel } from "./PageViewModel";
import { JobFeedback, JobInfo, JobOutput } from "../../../../services/NLIJobService";
import { FeedbackViewModel } from "./FeedbackViewModel";
import { TagViewModel } from "./TagViewModel";

export type JobOutputState = Readonly<
    { status: "not-loaded"|"loading" } |
    { status: "loaded", output: JobOutput } |
    { status: "load-failed", error: string }>;

export class JobViewModel extends ViewModel {

    constructor(public readonly page: PageViewModel, info: JobInfo) {
        super();
        this.jobID = info.jobID;
        this.startTime = info.startTime;
        this.question = info.question;
        this._status = info.status;
        this._statusMessage = info.statusMessage;
        this._outputState = { status: "not-loaded" };
        this._feedback = info.feedback;
        this._tags = info.tags;
        this.feedbackUpViewModel = new FeedbackViewModel(this, "up");
        this.feedbackDownViewModel = new FeedbackViewModel(this, "down");
        this.tagViewModel = new TagViewModel(this);
    }

    readonly jobID: string;
    readonly startTime: number;
    readonly question: string;
    readonly feedbackUpViewModel: FeedbackViewModel;
    readonly feedbackDownViewModel: FeedbackViewModel;
    readonly tagViewModel: TagViewModel;

    private _status: string;
    private _statusMessage?: string;
    private _outputState: JobOutputState;
    private _isSelected = false;
    private _age: string = "now";
    private _showSQL = false;
    private _canShowSQL = false;
    private _feedback?: JobFeedback;
    private _tags: string[];

    get status() {
        return this._status;
    }

    set status(value: string) {
        if (value !== this._status) {
            this._status = value;
            this.updateViews();
        }
    }

    get statusMessage() {
        return this._statusMessage;
    }

    private set statusMessage(value: string|undefined) {
        if (value !== this._statusMessage) {
            this._statusMessage = value;
            this.updateViews();
        }
    }

    get feedback() {
        return this._feedback;
    }

    set feedback(value: JobFeedback|undefined) {
        this._feedback = value;
        this.updateViews();
    }

    get tags() {
        return this._tags;
    }

    set tags(value: string[]) {
        this._tags = value;
        this.updateViews();
    }

    get outputState() {
        return this._outputState;
    }

    private set outputState(value: JobOutputState) {
        if (value !== this._outputState) {
            this._outputState = value;
            this.updateViews();
        }
    }

    get isSelected() {
        return this._isSelected;
    }

    set isSelected(value: boolean) {
        if (value !== this._isSelected) {
            this._isSelected = value;
            if (value) {
                if (this.page.selectedJob !== this) {
                    this.page.selectedJob = this;
                }
                if (this.status !== "RUNNING") {
                    this.loadOutput();
                }
            } else {
                if (this.page.selectedJob === this) {
                    this.page.selectedJob = undefined;
                }
            }
        }
    }

    get age() {
        return this._age;
    }

    updateAge(serverTime: number) {
        const age = Math.round((serverTime - this.startTime)/1000);
        this._age = age < 5 ? "now" :
            age < 60 ? `${age}s` :
            age < 3600 ? `${Math.floor(age / 60)}m` :
            age < 86400 ? `${Math.floor(age / 3600)}h` :
            `${Math.floor(age / 86400)}d`;
        this.updateViews();
    }

    updateStatus(status: string, statusMessage?: string) {
        if (status !== this.status) {
            this.page.invalidateJobList();
        }
        this.status = status;
        this.statusMessage = statusMessage;

        if (this.isSelected && status !== "RUNNING") {
            this.loadOutput();
        }
    }

    async loadOutput() {
        if (this.outputState.status === "not-loaded") {
            try {
                this.outputState = { status: "loading" };
                const output = await this.page.jobService.loadJobOutput(this.jobID);
                this.canShowSQL = !!output.Response && (!!output.Error || !!output.Data);
                this.outputState = { status: "loaded", output };
            } catch (error) {
                this.outputState = { status: "load-failed", error: this.formatError(error) };
            }
        }
    }

    get canShowSQL() {
        return this._canShowSQL;
    }

    private set canShowSQL(value: boolean) {
        if (value !== this._canShowSQL) {
            this._canShowSQL = value;
            if (!value) {
                this._showSQL = false;
            }
            this.updateViews();
        }
    }

    get showSQL() {
        return this._showSQL;
    }

    set showSQL(value: boolean) {
        if (value !== this._showSQL) {
            this._showSQL = value;
            this.updateViews();
        }
    }

    get canDownloadOutput() {
        return this.outputState.status === "loaded" && (!!this.outputState.output.Response || !!this.outputState.output.Data || !!this.outputState.output.Error);
    }

    downloadOutput() {
        if (this.outputState.status === "loaded") {
            const output = this.outputState.output;
            let file  = this.showSQL ? output.Response : (output.Error || output.Data || output.Response);

            if (file) {
                this.page.jobService.download(this.jobID, file.name, file.extension);
            }
        }
    }

    async runJob() {
        this.page.isSubmitting = true;
        const jobInfo = await this.page.jobService.createJob(this.page.modelAlias, this.question);
        this.page.addJob(jobInfo);
        this.page.isSubmitting = false;
    }

    async deleteJob() {
        this.page.isSubmitting = true;
        await this.page.jobService.deleteJob(this.jobID);
        this.page.removeJob(this);
        this.page.isSubmitting = false;
    }
}