import { ViewModel } from "mvvm/ViewModel";
import { JobViewModel } from "./JobViewModel";

export class TagViewModel extends ViewModel {

    constructor(
        public readonly job: JobViewModel) {
        super();
    }

    private _tags: string[] = [];
    private _isSaving = false;
    private _saveError?: { message: string, detail: string};
    private _isOpen = false;

    get isSaving() {
        return this._isSaving;
    }

    set isSaving(value: boolean) {
        this._isSaving = value;
        this.updateViews();
    }

    get saveError() {
        return this._saveError;
    }

    set saveError(value: { message: string, detail: string} | undefined) {
        this._saveError = value;
        this.updateViews();
    }

    get tags() {
        return this._tags;
    }

    set tags(value: string[]) {
        this._tags = value;
        this.updateViews();
    }

    get isOpen() {
        return this._isOpen;
    }

    set isOpen(value: boolean) {
        if (value !== this._isOpen) {
            this._isOpen = value;
            if (value) {
                this.saveError = undefined;
                this._tags = this.job.tags;
            }
            this.updateViews();
        }
    }

    async update() {
        this.saveError = undefined;
        this.isSaving = true;

        const currentTags = this.tags.map(tag => tag.trim());
        const jobTags = this.job.tags || [];
        if (currentTags.filter(e => e !== "").length === 0) {
            await this.remove(jobTags);
        }
        else if (jobTags.length < currentTags.length) {
            await this.add();
        }
        else if (jobTags.length > currentTags.length) {
            const missingTag = jobTags.find(tag => !currentTags.includes(tag));

            if (missingTag) {
                await this.remove([missingTag]);
            }
        }

        this.isSaving = false;
    }

    async add() {
        try {
            await this.job.page.jobService.addJobTags(this.job.jobID, this.tags);
            this.job.tags = this.tags;
            this.isSaving = false;
        } catch (error: any) {
            this.saveError = {
                message: "Save failed",
                detail: this.formatError(error)
            };
        }
    }

    async remove(tags: string[]) {
        this.isSaving = true;
        try {
            await this.job.page.jobService.removeJobTags(this.job.jobID, tags);
            this.job.tags = this.job.tags?.filter(tag => !tags.includes(tag));
            this.job.feedback = undefined;
            this.isSaving = false;
        } catch (error: any) {
            this.saveError = {
                message: "Delete failed",
                detail: this.formatError(error)
            };
            this.isSaving = false;
        }
    }

    async clearTags() {
        this.isSaving = true;
        try {
            await this.job.page.jobService.removeJobTags(this.job.jobID, this.tags);
            this.job.tags = [];
            this.job.feedback = undefined;
            this.isSaving = false;
        } catch (error: any) {
            this.saveError = {
                message: "Delete failed",
                detail: this.formatError(error)
            };
            this.isSaving = false;
        }
    }
}