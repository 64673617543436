import { ViewModel } from "mvvm/ViewModel";
import { JobViewModel } from "./JobViewModel";
import { JobInfo, NLIJobService } from "../../../../services/NLIJobService";
import { QuestionViewModel } from "./QuestionViewModel";

export type JobListState = Readonly<
    { status: "not-loaded"|"loading"|"updating" } |
    { status: "load-failed", error: string } |
    { status: "update-scheduled"|"idle", timeout: number }>;


export class PageViewModel extends ViewModel {
    constructor(public readonly jobService: NLIJobService, public readonly modelAlias: string) {
        super();
        this.question = new QuestionViewModel(this);
        this.initialize();
    }

    readonly question: QuestionViewModel;
    private _selectedJob?: JobViewModel;
    private _jobs: readonly JobViewModel[] = [];
    private _jobListState: JobListState = { status: "not-loaded" };
    private _clockSkew: number|null = null;
    private _isSubmitting: boolean = false;


    get jobs() {
        return this._jobs;
    }

    private set jobs(value: readonly JobViewModel[]) {
        if (value !== this._jobs) {
            this._jobs = value;
            this.updateViews();
        }
    }

    get jobListState() {
        return this._jobListState;
    }

    private set jobListState(value: JobListState) {
        if (value !== this._jobListState) {
            this._jobListState = value;
            this.updateViews();
        }
    }

    addJob(jobInfo: JobInfo) {
        const job = new JobViewModel(this, jobInfo);
        this.jobs = [job, ...this._jobs];
        this.selectedJob = job;
        this.invalidateJobList();
    }

    removeJob(job: JobViewModel) {
        if (job === this._selectedJob) {
            this.selectedJob = undefined;
        }
        this.jobs = this._jobs.filter(j => j !== job);
        this.invalidateJobList();
    }

    get selectedJob() {
        return this._selectedJob;
    }

    set selectedJob(value: JobViewModel|undefined) {
        const previousValue = this._selectedJob;
        if (value !== previousValue) {
            this.question.selectedJob = value;
            this._selectedJob = value;
            if (previousValue && previousValue.isSelected) {
                previousValue.isSelected = false;
            }
            if (value && !value.isSelected) {
                value.isSelected = true;
            }
            this.updateViews();
        }
    }

    private async initialize() {
        let serverTime: number;
        try {
            this.jobListState = { status: "loading" };
            const result = await this.jobService.loadJobs();
            const jobs = result.jobs.map(job => new JobViewModel(this, job));
            this.jobs = jobs;
            serverTime = result.serverTime;
        } catch (error) {
            this.jobListState = { status: "load-failed", error: this.formatError(error) };
            serverTime = Math.round(Date.now());
        }
        this.scheduleJobUpdates(serverTime);
    }

    private scheduleJobUpdates(serverTime: number) {
        const pollingInterval = 5000;
        const clientTime = Math.round(Date.now());
        const skew = clientTime - serverTime;
        if (this._clockSkew === null || skew < this._clockSkew) {
            this._clockSkew = skew;
        }
        const runningJobs: {[jobID: string]: JobViewModel} = {};
        let runningJobCount = 0;
        this.jobs.forEach(job => {
            job.updateAge(serverTime);
            if (job.status === "RUNNING") {
                runningJobs[job.jobID] = job;
                runningJobCount++;
            }
        });
        if (this._timerStopped) {
            return;
        }
        if (runningJobCount > 0) {
            const timeout = window.setTimeout(async () => {
                this._jobListState = { status: "updating" };
                let newServerTime: number;
                try {
                    const result = await this.jobService.loadJobStates(Object.keys(runningJobs));
                    result.jobStates.forEach(jobState => {
                        const job = runningJobs[jobState.jobID];
                        if (job) {
                            job.updateStatus(jobState.status, jobState.statusMessage);
                        }
                        // TODO: handle deleted job
                    });
                    newServerTime = result.serverTime;
                } catch (error) {
                    console.error("Failed to update job states", error);
                    newServerTime = Math.round(Date.now()) + (this._clockSkew ?? 0);
                }
                this.scheduleJobUpdates(newServerTime);
            }, pollingInterval);
            this.jobListState = { status: "update-scheduled", timeout };
        } else {
            const timeout = window.setTimeout(() => {
                const newServerTime = Math.round(Date.now()) + (this._clockSkew ?? 0);
                this.scheduleJobUpdates(newServerTime);
            }, pollingInterval);
            this._jobListState = { status: "idle", timeout };
        }
    }

    private _jobListInvalidated = false;
    private _timerStopped = false;

    invalidateJobList() {
        this._jobListInvalidated = true;
    }

    get jobListInvalidated() {
        return this._jobListInvalidated;
    }

    stopTimer() {
        this._timerStopped = true;
    }

    get isSubmitting() {
        return this._isSubmitting;
    }

    set isSubmitting(value: boolean) {
        if (this._isSubmitting !== value) {
            this._isSubmitting = value;
            this.updateViews();
        }
    }
}