import { PageViewModel } from "./ViewModels/PageViewModel";
import { NLIJobService } from "../../../services/NLIJobService";
import { useEffect, useMemo } from "react";
import { PageView } from "./Views/PageView";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useLocation } from "react-router-dom";
import { useSession } from "components/wrappers/SessionProvider";
import { endSubmitting, requestCompleted, requestStart } from "redux/slices/app";
import { RefreshAction } from "redux/actions/Refresh";
import { RunNLIPageWrapper } from "./styles";

export default function RunNLIPage() {

    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.user);
    const modelInfo = user.models[user.modelIndex];
    const pageLocation = useLocation().pathname;
    const session = useSession();


    const nliJobService = useMemo(() => new NLIJobService(dispatch, session, modelInfo), []);
    const pageViewModel = useMemo(() => new PageViewModel(nliJobService, modelInfo.alias), []);

    useEffect(() => {
        nliJobService.dispatch = dispatch;
        nliJobService.session = session;
        nliJobService.model = modelInfo;
    }, [dispatch, session, modelInfo]);

    useEffect(() => {
        dispatch(requestCompleted());
        dispatch(endSubmitting());
    }, []);

    const cleanup = async() => {
        pageViewModel.stopTimer();
        if (pageViewModel.jobListInvalidated) {
            dispatch(requestStart());
            await dispatch(RefreshAction(session));
            dispatch(requestCompleted());
        }
    }

    useEffect(() => {
        return () => {
            if (window.location.pathname !== pageLocation) {
                cleanup().catch(console.error);
            }
        };
    }, [pageLocation]);

    return (
        <RunNLIPageWrapper className="fill negate-content-padding text-content flex-1 flex-column">
            <PageView model={pageViewModel} />
        </RunNLIPageWrapper>
    );
}